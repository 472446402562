import colors from '@instaseat/lib/themes/colors'
import styled from 'styled-components/native'

export const TextArea = styled.TextInput.attrs({
  multiline: true,
  numberOfLines: 4,
  placeholder: 'Notes',
  placeholderTextColor: colors.textColor + '80'
})<{focus?: boolean, isFilled: boolean}>`
  background-color: ${(props) => (
    (props.focus && props.editable) || props.isFilled)
? 'transparent'
: colors.bgGrey};
  color: ${colors.textColor + '80'};
  padding: 15px 20px;
  width: 100%;
  align-self: center;
  outline-style: none;
  font-family: 'Lato';
  font-size: 13px;
  line-height: 16px;
  border-radius: 1vmax;
  box-shadow: 0px 4px 8px ${colors.textColor + '26'};
  border: 1px solid #E4E4E4;
`
