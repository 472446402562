import { Reducer } from 'redux'

import { TurnsActionTypes, TurnsState, TurnsActionConsts } from './types'

export const initialState: TurnsState = {
  turnDetails: undefined,
  turnToDrag: {
    id: '',
    newPosition: null
  },
  isFetching: false,
  error: '',
  turnID: ''
}

export const turnsReducer: Reducer<TurnsState, TurnsActionTypes> = (
  state = initialState,
  action
): TurnsState => {
  switch (action.type) {
    case TurnsActionConsts.ADD_NEW_TURN_PENDING:
    case TurnsActionConsts.GET_TURN_DETAILS_PENDING:
      return {
        ...state,
        turnDetails: undefined,
        isFetching: true,
        error: ''
      }
    case TurnsActionConsts.CANCEL_BY_HOST_PENDING:
    case TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_PENDING:
    case TurnsActionConsts.SEAT_CUSTOMER_PENDING:
    case TurnsActionConsts.RETURN_TURN_TO_WAILIST_PENDING:
    case TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_PENDING:
    case TurnsActionConsts.REMOVE_EMPTY_VIP_PENDING:
    case TurnsActionConsts.UPDATE_TURN_NOTES_PENDING:
      return {
        ...state,
        isFetching: true,
        error: ''
      }
    case TurnsActionConsts.ADD_NEW_TURN_REJECTED:
    case TurnsActionConsts.GET_TURN_DETAILS_REJECTED:
    case TurnsActionConsts.CANCEL_BY_HOST_REJECTED:
    case TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_REJECTED:
    case TurnsActionConsts.SEAT_CUSTOMER_REJECTED:
    case TurnsActionConsts.RETURN_TURN_TO_WAILIST_REJECTED:
    case TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_REJECTED:
    case TurnsActionConsts.REMOVE_EMPTY_VIP_REJECTED:
    case TurnsActionConsts.UPDATE_TURN_NOTES_REJECTED:
      return {
        ...state,
        isFetching: false,
        error: action.payload
      }
    case TurnsActionConsts.ADD_NEW_TURN_FULFILLED:
    case TurnsActionConsts.CANCEL_BY_HOST_FULFILLED:
    case TurnsActionConsts.CUSTOMER_PENDING_CONFIRMATION_FULFILLED:
    case TurnsActionConsts.SEAT_CUSTOMER_FULFILLED:
    case TurnsActionConsts.RETURN_TURN_TO_WAILIST_FULFILLED:
    case TurnsActionConsts.GET_ESTIMATED_QUOTE_BY_PARTY_FULFILLED:
    case TurnsActionConsts.REMOVE_EMPTY_VIP_FULFILLED:
    case TurnsActionConsts.UPDATE_TURN_NOTES_FULFILLED:
      return {
        ...state,
        isFetching: false
      }
    case TurnsActionConsts.GET_TURN_DETAILS_FULFILLED:
      return {
        ...state,
        turnDetails: action.payload,
        isFetching: false
      }
    case TurnsActionConsts.SET_TURN_TO_DRAG:
      return {
        ...state,
        turnToDrag: action.payload,
        isFetching: false
      }
    case TurnsActionConsts.SAVE_TURN_ID:
      return {
        ...state,
        turnID: action.payload
      }
    default:
      return state
  }
}
