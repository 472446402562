import React, { useEffect, useState } from 'react'
import { CustomModal } from '../shared/modal'
import NotifyModalContent from './content/Content'
import { useAppSelector } from 'hooks/redux'
import { hideModal } from 'store/modules/Modals'
import { NotifyIcon } from './styled'
import { customerPendingConfirmation } from 'store/modules/Turns'
import { useDispatch } from 'store'
import { showToast } from 'utils/toastHandler'
import { ToastStatus } from 'components/customToast'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'

const header = {
  title: 'Notify',
  IconComponent: <NotifyIcon />
}

const NotifyModal = () => {
  const showNotifyModal = useAppSelector((store) => store.modals.showModal) === 'notify'
  const turnId = useAppSelector((store) => store.waitlist.selectedTurn?._id)?.toString()
  const { error } = useAppSelector((store) => store.turns)
  const [errorMessage, setErrorMessage] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    error &&
      typeof error === 'string' &&
      error.includes('to re-notify this customer')
      ? setErrorMessage(error)
      : setErrorMessage('Try again in a few seconds!')
  }, [error])

  const onPress = async () => {
    const success = turnId && await dispatch(customerPendingConfirmation(turnId))
    if (success) {
      showToast({
        title: 'Customer notified.',
        description: 'The customer have been successfully notified.',
        status: ToastStatus.success
      })
    } else {
      showToast({
        title: 'You can\'t notify this turn right now.',
        description: errorMessage,
        status: ToastStatus.error
      })
    }
    dispatch(hideModal())
    dispatch(setSelectedHistoryTurn(''))
  }
  const isLoading = useAppSelector((store) => store.turns.isFetching)

  return (
    <CustomModal
      showModal={showNotifyModal}
      onClose={() => dispatch(hideModal())}
      header={header}
      BodyComponent={(
        <NotifyModalContent
          onPress={onPress}
          isLoading={isLoading}
          isVisible={showNotifyModal}
        />
      )}
    />
  )
}

export { NotifyModal }
