/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ButtonsWrapper,
  CancelButton,
  CancelText,
  ContentWrapper,
  EditButton,
  EditText,
  FormColumn,
  ListRow,
  ListRowDetail,
  ListRowTitle,
  TextArea,
  ShowMoreButton,
  Wrapper
} from './styled'
import { TurnDetailsPopulatedTurn } from '@instaseat/lib/interfaces/turn'
import { ChevronUpIcon, ChevronDownIcon, Skeleton } from 'native-base'
import { TurnStatus, TurnStatusToShow, TurnTypes } from '@instaseat/lib/enums/turn'
import { differenceInSeconds, formatDistanceToNowStrict } from 'date-fns'
import { roundCustomerWaitTime } from '@instaseat/lib/utils/helpers/helpers'
import { useAppSelector, useAppDispatch } from 'hooks/redux'
import { ControllerTextArea } from 'components/addTurnModal/controllerTextArea'
import { useForm } from 'react-hook-form'
import { hideModal } from 'store/modules/Modals'
import { updateTurnNotes } from 'store/modules/Turns'
import { LoadingSpinner } from '@instaseat/lib/components'
import colors from '@instaseat/lib/themes/colors'

interface ITurnDetailModalContentProps {
  isLoading: boolean
  data: TurnDetailsPopulatedTurn | undefined
}

interface EditFormData {
  notes: string;
}

const TurnDetailModalContent = ({ isLoading, data }: ITurnDetailModalContentProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isShowMoreActive, setIsShowMoreActive] = useState<boolean>(false)
  const roundingValue = useAppSelector((store) => store.host.profileData.unit.roundingValue)

  const dispatch = useAppDispatch()

  const dateToReadableFormat = useCallback((date: Date|undefined) => {
    if (!date) return '-/-/--  -- : --  hs'
    const dateFormatted = new Date(date)
      .toLocaleString('en-US', { dateStyle: 'short', hourCycle: 'h23', timeStyle: 'short' })
    return `${dateFormatted}  hs`
  }, [])

  const secToReadableFormatHours = useCallback((seconds: number | undefined) => {
    if (seconds === undefined) return '--:--'

    let result = ''
    if (seconds < 0) result = '-'

    seconds = Math.abs(seconds)
    const minutes = Math.floor(seconds / 60) < 10 ? '0' + Math.floor(seconds / 60) : Math.floor(seconds / 60)

    result += `${minutes}:`
    result += `${('0' + (Math.floor(seconds % 60))).slice(-2)} min`
    return result
  }, [])

  const minToReadableFormatHours = useCallback((minutes: number | undefined) => {
    if (minutes === undefined) return '--:--'

    let result = ''
    if (minutes < 0) result = '-'

    result += `${minutes} min`
    return result
  }, [])

  const waitTimeFormatted = useCallback(() => {
    if (!data) return

    let secondsDiff

    if (data.status === TurnStatus.customerWaiting) {
      const refTime = new Date(data.reinsertedAt || data.createdAt)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (data.status === TurnStatus.customerWaiting && data.type === TurnTypes.VIP && data.vipCreationDate) {
      const refTime = new Date(data.vipCreationDate)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }
    if (data.status === TurnStatus.pendingConfirmation && data.courtesyTimeExpiration) {
      const refTime = new Date(data.courtesyTimeExpiration)
      secondsDiff = Number(formatDistanceToNowStrict(refTime, { unit: 'second' }).split(' ')[0])
    }

    return secondsDiff
  }, [data])

  const courtesyTimeFormatted = useCallback(() => {
    if (!data || data.status === TurnStatus.customerWaiting) return
    if (data.status === TurnStatus.pendingConfirmation && data.courtesyTime) {
      return new Date().getTime() - new Date(data.courtesyTime).getTime()
    }
    if (data.status === TurnStatus.customerOnItsWay && data.isComingTime && data.courtesyTime) {
      return new Date(data.isComingTime).getTime() - new Date(data.courtesyTime).getTime()
    }
    if (data.seatTime && data.courtesyTime) {
      return differenceInSeconds(new Date(data.seatTime), new Date(data.courtesyTime))
    }
  }, [data])

  const toggleEditMode = () => {
    if (data?.status === 'customerWaiting') {
      setIsEditing(true)
    }
  }

  const toggleShowMore = () => {
    setIsShowMoreActive(!isShowMoreActive)
  }

  const handleCancel = () => {
    setIsEditing(false)
    reset()
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
    reset
  } = useForm<EditFormData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      notes: data?.notes || ''
    }
  })

  useEffect(() => {
    reset({
      notes: data?.notes || ''
    })
    setIsEditing(false)
    setIsShowMoreActive(false)
  }, [data])

  const formattedData = useMemo(() => ({
    'First Name': data?.customer.firstName || '---',
    'Last Name': data?.customer.lastName || '---',
    'Phone number': data?.customer.phone,
    'Party size': data?.party,
    'Check in date': dateToReadableFormat(data?.checkInTime),
    Status: TurnStatusToShow[data?.status || 'customerWaiting'],
    Type: data?.type,
    'Current Wait Time': minToReadableFormatHours(data?.waitTime),
    'Original Wait Time': minToReadableFormatHours(data?.originalWaitTime),
    'Rounded Original Wait Time': minToReadableFormatHours(roundCustomerWaitTime(
      data?.originalWaitTime || 0,
      roundingValue)),
    'Elapsed Time': secToReadableFormatHours(waitTimeFormatted()),
    Quote: `${data?.quote || '---'} min`,
    Notes: data?.notes || '',
    'Elapsed Courtesy Time': secToReadableFormatHours(Math.floor((courtesyTimeFormatted() ?? 0) / 1000)),
    'Seat date': dateToReadableFormat(data?.seatTime),
    'Cancelation date': dateToReadableFormat(data?.cancelTime),
    'Reinserted at': dateToReadableFormat(data?.reinsertedAt),
    Preferences: data?.turnOptions?.length ? data.turnOptions : ['---']
  }), [courtesyTimeFormatted,
    data?.checkInTime,
    data?.customer.firstName,
    data?.customer.lastName,
    data?.customer.phone,
    data?.party,
    data?.quote,
    data?.seatTime,
    data?.status,
    data?.turnOptions,
    data?.type,
    data?.reinsertedAt,
    data?.waitTime,
    data?.cancelTime,
    data?.originalWaitTime,
    data?.notes,
    dateToReadableFormat,
    secToReadableFormatHours,
    minToReadableFormatHours,
    waitTimeFormatted,
    roundCustomerWaitTime
  ])

  const onEdit = async (values: EditFormData) => {
    if (data) {
      await dispatch(updateTurnNotes({
        notes: values.notes,
        turnId: data._id.toString()
      }))
      toggleEditMode()
      dispatch(hideModal())
    }
  }

  return (
    <ContentWrapper space="8px">
      <Skeleton.Text lines={12} isLoaded={!isLoading || isSubmitting}>
        {isShowMoreActive
          ? Object.entries(formattedData).map(([key, data]) => (
            <ListRow key={key}>
              <ListRowTitle>{key}</ListRowTitle>
              {Array.isArray(data)
                ? <ListRowDetail capitalize>{data.join(' / ')}</ListRowDetail>
                : <ListRowDetail capitalize={!key.includes('time') && !key.includes('date')}>{data}</ListRowDetail>}
            </ListRow>
          ))
          : Object.entries(formattedData).slice(0, 5).map(([key, data]) => (
            <ListRow key={key}>
              <ListRowTitle>{key}</ListRowTitle>
              {Array.isArray(data)
                ? <ListRowDetail capitalize>{data.join(' / ')}</ListRowDetail>
                : <ListRowDetail capitalize={!key.includes('time') && !key.includes('date')}>{data}</ListRowDetail>}
            </ListRow>
          ))}
        <Wrapper>
          {!isShowMoreActive
            ? (
              <ShowMoreButton onPress={toggleShowMore}>
                Show more
                <ChevronDownIcon size='6' color={colors.primary} />
              </ShowMoreButton>
              )
            : (
              <ShowMoreButton onPress={toggleShowMore}>
                Show less
                <ChevronUpIcon size='6' color={colors.primary} />
              </ShowMoreButton>
              )}
        </Wrapper>
      </Skeleton.Text>
      <Skeleton mt='10px' width="90%" height="100px" isLoaded={!isLoading || isSubmitting}>
        {
          data?.canceledReason && (
          <>
            <ListRowTitle alignSelf="flex-start">Canceled reason</ListRowTitle>
            <TextArea editable={false} value={data?.canceledReason || '---'} />
          </>
          )
        }
        <Wrapper>
          <ListRowTitle mt="3%">
            {!isEditing ? 'Notes' : 'Edit Notes'}
          </ListRowTitle>
        </Wrapper>
        <FormColumn>
          <ControllerTextArea
            isEditable={isEditing && !isSubmitting}
            control={control}
            label=''
            name='notes'
            isInvalid
            onResponderStart={toggleEditMode}
          />
        </FormColumn>
        {isEditing
          ? (
            <ButtonsWrapper>
              <CancelButton>
                <CancelText onPress={() => handleCancel()}>
                  Cancel
                </CancelText>
              </CancelButton>
              <EditButton disabled={isDirty}>
                {!isSubmitting
                  ? <EditText onPress={handleSubmit(onEdit)}>Edit</EditText>
                  : <LoadingSpinner />}
              </EditButton>
            </ButtonsWrapper>
            )
          : ''}
      </Skeleton>
    </ContentWrapper>
  )
}

export default TurnDetailModalContent
