import EditIcon from '@instaseat/lib/assets/icons/EditIcon'
import colors from '@instaseat/lib/themes/colors'
import { HStack, Text, VStack, Button, View } from 'native-base'
import styled from 'styled-components/native'

export const ContentWrapper = styled(VStack)`
  padding: 24px;
  align-items: center;
`

export const ListRow = styled(HStack)`
  justify-content: space-between;
  align-items: baseline;
  width: 90%;
  padding-bottom: 5px;
`

export const ListRowTitle = styled(Text)`
  text-transform: capitalize;
  font-family: 'Lato';
  color: ${colors.webModalGreyText};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-right: 30px;
  flex: 1;
`

export const ListRowDetail = styled(ListRowTitle)<{capitalize?: boolean}>`
  font-style: italic;
  font-size: 12px;
  text-align: center;
  align-self: center;
  margin-right: 0;
  text-transform: ${(props) => props.capitalize && 'capitalize'};
`

export const TextArea = styled.TextInput.attrs({
  multiline: true,
  numberOfLines: 4,
  placeholder: 'Notes',
  placeholderTextColor: colors.textColor + '80'
})`
  margin-top: 10px;
  background-color: ${colors.bgGrey};
  padding: 15px 20px;
  width: 90%;
  align-self: center;
  outline-style: none;
  font-family: 'Lato';
  font-size: 13px;
  line-height: 16px;
  border-radius: 1vmax;
  border: 1px solid #E4E4E4;
`
export const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

export const EditNotesButtonIcon = styled(EditIcon).attrs({
  color: '#FFF',
  width: '20px',
  innerWidth: '20px'
})``

export const CancelButton = styled(View)`
  width: 100px;
  height: 25px;
  background: transparent;
  border-width: 0.5px;
  border-color: "#210F0B";
  justify-content: center;
  border-radius: 8px;
`
export const ShowMoreButton = styled(Text)`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  color: ${colors.primary}
`

export const CancelText = styled(Text)`
  font-family: "Lato";
  font-size: 12x;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
`
export const EditButton = styled(Button).attrs({
  borderRadius: '8px',
  width: '100px',
  marginLeft: '10px'
})`
  width: 100px;
  height: 26px;
`

export const EditText = styled(Text)`
  font-family: "Lato";
  font-size: 12x;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.white};
`

export const ButtonsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  width: 45%;
`

export const FormColumn = styled(HStack)`
  width: 100%;
  justify-content: center;
`
