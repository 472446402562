/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import CustomIcons from '@instaseat/lib/assets/icons'
import { IconName } from '@instaseat/lib/assets/icons/types'
import { CustomerNameText, IconWrapper, PressableWaitlistTd, WaitlistTd, WaitlistTr } from './styled'
import colors from '@instaseat/lib/themes/colors'
import { PopulatedWaitlistItem } from '@instaseat/lib/interfaces/unit'
import { TurnStatus, TurnTypes } from '@instaseat/lib/enums/turn'
import { HStack, PresenceTransition, Pressable, View } from 'native-base'
import ActionRow from './ActionRow'
import { setSelectedHistoryTurn } from 'store/modules/Waitlist'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'hooks/redux'
import { QuoteAndElapsedTimeCell } from './QuoteAndElapsedTimeCell'
import { DraggableProvided } from 'react-beautiful-dnd'

interface ITableRowProps {
  turn: PopulatedWaitlistItem,
  shouldUpdateTime: boolean,
  provider: DraggableProvided,
  selectedId: string
}

const TableRow = ({ turn, shouldUpdateTime, provider, selectedId }: ITableRowProps) => {
  const [isHidden, setIsHidden] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const selectedHistoryRow = useAppSelector((store) => store.waitlist.selectedHistoryTurn)
  const customerName = `${turn?.customer?.firstName} ${turn?.customer?.lastName}`.trim()
  const dispatch = useDispatch()

  const hideRowFromView = useCallback(() => {
    setTimeout(() => {
      setIsHidden(true)
    }
    , 1000)
  }
  , [])

  useEffect(() => {
    if (turn.status === TurnStatus.canceledByCustomer ||
        turn.status === TurnStatus.canceledByHost || turn.status === TurnStatus.seated) {
      hideRowFromView()
    }
    if (turn?.type === TurnTypes.VIP && turn?._id === undefined) {
      setIsHidden(true)
    }
  }
  , [turn, hideRowFromView])

  useEffect(() => {
    setIsSelected(selectedHistoryRow === turn._id?.toString())
  }, [turn._id, selectedHistoryRow])

  const getStatusIcon = useCallback((status: TurnStatus) => {
    switch (status) {
      case TurnStatus.customerWaiting:
        return (
          <CustomIcons
            iconName={IconName.ClockIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.canceledByCustomer:
      case TurnStatus.canceledByHost:
        return (
          <CustomIcons
            iconName={IconName.CancelledIcon}
            iconProps={{ fill: colors.errorColor, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.seated:
        return (
          <CustomIcons
            iconName={IconName.CheckIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.customerOnItsWay:
        return (
          <CustomIcons
            iconName={IconName.HostOnItsWayIcon}
            iconProps={{ fill: colors.success, height: '24px', width: '24px' }}
          />
        )
      case TurnStatus.pendingConfirmation:
        return (
          <CustomIcons
            iconName={IconName.HostOnItsWayIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
      default:
        return (
          <CustomIcons
            iconName={IconName.ClockIcon}
            iconProps={{ fill: colors.tertiary, height: '24px', width: '24px' }}
          />
        )
    }
  }, [])

  return (
    <WaitlistTr
      display={isHidden ? 'none' : 'table-row'}
      {...provider.draggableProps}
      ref={provider.innerRef}
      bordered={!!turn.movedWithDragAndDrop}
    >
      <WaitlistTd
        width="40px"
        {...provider.dragHandleProps}
      >
        <IconWrapper>
          <CustomIcons
            iconName={IconName.DragIcon}
            iconProps={{
              width: '24px',
              height: '24px',
              fill: selectedId === turn._id?.toString()
                ? colors.primary
                : turn.movedWithDragAndDrop ? colors.tertiary : colors.textColor
            }}
          />
        </IconWrapper>
      </WaitlistTd>
      <PressableWaitlistTd id={turn._id?.toString()} width="90px" justifyContent='initial'>
        <HStack h='100%' alignItems='center' textAlign="start">
          <View alignSelf="center" pr="10px">
            <CustomIcons
              iconName={IconName.LogoIcon}
              iconProps={{
                width: '14px',
                height: '14px',
                fill: turn.type === TurnTypes.VIP ? colors.primary : 'transparent'
              }}
            />
          </View>
          <CustomerNameText
            style={{
              fontWeight: (turn.party !== null && turn.party >= 6) ? 'bold' : 'normal'
            }}
          >
            {customerName}
          </CustomerNameText>
        </HStack>
      </PressableWaitlistTd>
      <PressableWaitlistTd
        id={turn._id?.toString()}
        width="80px"
        justifyContent='initial'
        style={{
          fontWeight: (turn.party !== null && turn.party >= 6) ? 'bold' : 'normal'
        }}
      >
        {turn?.customer?.phone}
      </PressableWaitlistTd>
      <PressableWaitlistTd
        id={turn._id?.toString()}
        width="40px"
        justifyContent='initial'
      >
        {turn?.notes && turn.notes.length > 10 ? `${turn.notes.substring(0, 10)}...` : turn?.notes}
      </PressableWaitlistTd>
      {!isSelected
        ? (
          <>
            <PressableWaitlistTd
              id={turn._id?.toString()}
              width="50px"
              style={
              {
                fontWeight: (turn.party !== null && turn.party >= 6) ? 'bold' : 'normal',
                color: (turn.party !== null && turn.party >= 6) ? colors.tertiary : colors.textColor
              }
            }
            >
              {turn.party}
            </PressableWaitlistTd>
            <PressableWaitlistTd
              id={turn._id?.toString()}
              style={
              {
                textTransform: 'none',
                width: '70px'
              }
            }
            >
              <QuoteAndElapsedTimeCell turn={turn} shouldUpdate={shouldUpdateTime} />
            </PressableWaitlistTd>
            <WaitlistTd id={turn._id?.toString()} width="40px">
              <IconWrapper>
                {turn.status && getStatusIcon(turn.status)}
              </IconWrapper>
            </WaitlistTd>
            <WaitlistTd width="20px">
              <IconWrapper>
                <Pressable onPress={() => {
                  dispatch(setSelectedHistoryTurn(turn._id?.toString() || ''))
                }}
                >
                  <CustomIcons
                    iconName={IconName.SeeMoreIcon}
                    iconProps={{
                      width: '24px',
                      height: '24px',
                      fill: colors.textColor
                    }}
                  />
                </Pressable>
              </IconWrapper>
            </WaitlistTd>
          </>
          )
        : (
          <WaitlistTd colSpan={4} width='176px' height="50px">
            <PresenceTransition
              visible={isSelected}
              initial={{
                opacity: 0
              }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 550
                }
              }}
            >
              <ActionRow hideActionRow={() => dispatch(setSelectedHistoryTurn(''))} turnData={turn} />
            </PresenceTransition>
          </WaitlistTd>
          )}
    </WaitlistTr>
  )
}
export default TableRow
