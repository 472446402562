import React from 'react'
import { WarningIcon } from 'native-base'
import { Control, Controller, FieldValues, Path, UseFormClearErrors } from 'react-hook-form'
import { CustomTextArea } from 'components/shared/customTextArea'
import { ControllerTextAreaWrapper, ErrorLabel, Label } from './styled'

export interface ControllerTextAreaProps<TFormValues extends FieldValues> {
  control: Control<TFormValues>
  label: string
  name: Path<TFormValues>
  isInvalid?: boolean
  isEditable?: boolean
  errorMsg?: string
  onFocus?: UseFormClearErrors<TFormValues>
  onResponderStart?: () => void
}

const ControllerTextArea = <TFormValues extends FieldValues>({
  control,
  label,
  name,
  isInvalid,
  isEditable,
  errorMsg,
  onFocus,
  onResponderStart
}:ControllerTextAreaProps<TFormValues>) => {
  return (
    <ControllerTextAreaWrapper isInvalid={isInvalid}>
      <Label>{label}</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <CustomTextArea
              onBlur={onBlur}
              isEditable={isEditable}
              onFocus={() => onFocus?.(name)}
              onResponderStart={onResponderStart}
              onChangeText={onChange}
              value={typeof value === 'string' ? value : undefined}
            />
          )
        }}
        rules={{ required: true }}
      />
      <ErrorLabel
        leftIcon={<WarningIcon size={4} />}
      >
        {errorMsg}
      </ErrorLabel>
    </ControllerTextAreaWrapper>
  )
}

ControllerTextArea.defaultProps = {
  errorMsg: undefined,
  isInvalid: false,
  onFocus: undefined,
  onResponderStart: undefined,
  isEditable: undefined
}

export default ControllerTextArea
